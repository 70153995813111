export const lotoListReducer = (
    state = {
        allLotoIds: [],
        manager: null,
        allowedCount: "",
        details: {},
    },
    action
    ) => {
    switch (action.type) {
        case "FETCH_LOTO_IDS_SUCCESS":
        return {
            ...state,
            allLotoIds: action.payload,
        };
        case "FETCH_LOTO_MANAGER_SUCCESS":
        return {
            ...state,
            manager: action.payload,
        };
        case "FETCHING_ALLOWED_COUNT_SUCCESS":
        return {
            ...state,
            allowedCount: action.payload,
        };
        case "FETCHING_LOTO_DETAIL_SUCCESS":
        return {
            ...state,
            details: {
                ...state.details,
                [action.payload.id]: action.payload.data,
            },
        };
        default:
        return state;
    }
};

export const lotoDataReducer = (
    state = {
        loading: true,
        randameData: [],
    },
    action
    ) => {
    switch (action.type) {
        case "FETCH_LOTO_DATA":
        return {
            loading: true,
        };
        case "FETCH_LOTO_DATA_SUCCESS":
        return {
            loading: false,
            randameData: action.payload,
        };
        case "FETCH_LOTO_DATA_FAILED":
        return {
            loading: false,
            error: action.payload,
        };
        default:
        return state;
    }
};
