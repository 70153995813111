import { combineReducers } from "redux";
import { lotoListReducer } from "./lotoReducer";
import uiReducer from "./uiReducer";

const allReducers = combineReducers({
    loto: lotoListReducer,
    loading: uiReducer,
});

export default allReducers;
