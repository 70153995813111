import { useCallback } from "react";
import * as wagmi from "wagmi";
import { useProvider, useSigner } from "wagmi";
import { useDispatch } from "react-redux";
import { ethers } from "ethers";
import LotoContract from "../contracts/Loto.json";
import {
    startAction,
    stopAction,
    errorAction,
} from "../redux/reducers/uiActions";
import {
    FETCHING_LOTO_DETAIL,
    FETCHING_LOTO_DETAIL_SUCCESS,
    FETCHING_ALLOWED_COUNT,
    FETCHING_ALLOWED_COUNT_SUCCESS,
    FETCH_LOTO_MANAGER,
    FETCH_LOTO_MANAGER_SUCCESS,
    FETCH_LOTO_IDS,
    FETCH_LOTO_IDS_SUCCESS,
} from "../redux/actionConstants";

const useLotoContract = () => {
    const dispatch = useDispatch();
    const [signer] = useSigner();
    const provider = useProvider();

    const contract = wagmi.useContract({
        addressOrName: process.env.REACT_APP_LOTO_CONTRACT,
        contractInterface: LotoContract.abi,
        signerOrProvider: signer.data || provider,
    });

    const getLotoDetail = useCallback(
        async (_lotoId) => {
            dispatch(startAction(FETCHING_LOTO_DETAIL));
            try {
                const data = await contract.getLotoDetails(_lotoId);

                dispatch({
                    type: FETCHING_LOTO_DETAIL_SUCCESS,
                    payload: {
                        id: _lotoId,
                        data: {
                            ticketPrice: ethers.utils.formatEther(data[1].toString()),
                            pricePool: ethers.utils.formatEther(data[2].toString()),
                            players: data[3].length,
                            playersData: data[3],
                            winner: data[4],
                            active: data[5],
                        },
                    },
                });
            } catch (e) {
                console.log("error fetching loto detail", e);
                dispatch(
                    errorAction(
                        FETCHING_LOTO_DETAIL,
                        "Error while fetching allowed count"
                        )
                    );
            }
            dispatch(stopAction(FETCHING_LOTO_DETAIL));
        },
        [contract, dispatch]
        );

    const getLotoAllowedCount = useCallback(async () => {
        dispatch(startAction(FETCHING_ALLOWED_COUNT));
        try {
            const allowedPlayerCount = await contract.totalAllowedPlayers();
            dispatch({
                type: FETCHING_ALLOWED_COUNT_SUCCESS,
                payload: allowedPlayerCount.toString(),
            });
        } catch (e) {
            console.log("fetching loto allowed count failed", e);
            dispatch(
                errorAction(
                    FETCHING_ALLOWED_COUNT,
                    "Error while fetching allowed count"
                    )
                );
        }
        dispatch(stopAction(FETCHING_ALLOWED_COUNT));
    }, [contract, dispatch]);

    const getLotoManagerAddress = useCallback(async () => {
        dispatch(startAction(FETCH_LOTO_MANAGER));
        try {
            const manager = await contract.lotoManager();
            dispatch({
                type: FETCH_LOTO_MANAGER_SUCCESS,
                payload: manager,
            });
        } catch (e) {
            console.log("fetching loto manager failed", e);
            dispatch(
                errorAction(
                    FETCH_LOTO_MANAGER,
                    "Error while fetching loto manager"
                    )
                );
        }
        dispatch(stopAction(FETCH_LOTO_MANAGER));
    }, [contract, dispatch]);

    const fetchAllLotoIds = useCallback(async () => {
        dispatch(startAction(FETCH_LOTO_IDS));
        try {
            const lotteries = await contract.getAllLotoIds();
            dispatch({
                type: FETCH_LOTO_IDS_SUCCESS,
                payload: lotteries,
            });
        } catch (e) {
            console.log("fetching all lotoIds failed", e);
            dispatch(
                errorAction(FETCH_LOTO_IDS, "Error while fetching all lotoIds")
                );
        }
        dispatch(stopAction(FETCH_LOTO_IDS));
    }, [contract, dispatch]);

    return {
        contract,
        fetchAllLotoIds,
        getLotoManagerAddress,
        getLotoAllowedCount,
        getLotoDetail,
    };
};

export default useLotoContract;
