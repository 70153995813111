const messages = {
    "invalidFee()": "Mauvais montant d'ether.",
    "lotoNotFound()": "Ce tirage n'existe pas.",
    "lotoFull()": "Tirage complet.",
};

const eventMessages = {
    LotoCreated: "Nouveau tirage creer!",
    NewLotoPlayer: "Vous participez maintenant au tirage.",
    RandomnessRequested: "Le Vainqueur sera annouce d'ici 30 secondes.",
};

export const getEMessage = (message) => {
    const errorKey = message.match(/\w+\(\)/g);
    if (errorKey !== null) {
        if (messages[errorKey[0]]) {
            return messages[errorKey[0]];
        }
    }
    return message;
};

export const eventMessage = (eve) => {
    if (eventMessages[eve]) {
        return eventMessages[eve];
    }
    return eve;
};
