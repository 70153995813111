import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import useLotoContract from "../hooks/useLotoContract";
import Spinner from "./Spinner";
import { checkIfLoading, checkIfError } from "../redux/reducers/selector";
// import ToastMessage from './ToastMessage';
import { ethers } from "ethers";
import useLotoAction from "../hooks/useLotoActions";
import { getEMessage, eventMessage } from "../errorMessages";
import { useMetaMaskAccount } from "../context/AccountContext";
import { useContractEvent } from "wagmi";
import LotoLarge from "../assets/loto-large.svg";
import LotoDetailModal from "./LotoDetailModal";
import { ToastContainer } from "react-toastify";
import Confetti from 'react-confetti'
import LotoContract from "../contracts/Loto.json";

function Loto(props) {
    const {
        lotoId,
        enterLotoHandler,
        entryBtnLoaders,
        pickWinnerHandler,
        winBtnLoaders,
        setDeclareWinner,
    } = props;
    const [entryFee, setEntryFee] = useState("");
    const { connectedAddr } = useMetaMaskAccount();
    const { getLotoDetail } = useLotoContract();
    const { details, manager } = useSelector((state) => state.loto);
    const loadingState = useSelector((state) => state.loading);
    const isDetailLoading = checkIfLoading(
        loadingState,
        "FETCHING_LOTO_DETAIL_SUCCESS"
    );
    const [reloadInfo, setReloadInfo] = useState(false);

    useEffect(() => {
        (async () => {
            await getLotoDetail(lotoId);
        })();
    }, [entryBtnLoaders[lotoId], reloadInfo]);

    useContractEvent(
        {
            addressOrName: process.env.REACT_APP_LOTO_CONTRACT,
            contractInterface: LotoContract.abi,
        },
        "WinnerDeclared",
        (event) => {
            if (typeof details[lotoId.toString()] !== "undefined") {
                if (!details[lotoId.toString()].active) {
                    setDeclareWinner(true);
                    setReloadInfo(true);
                }
            }
        },
        {
          once: true,
        }
    );

    return (
    <div className="app-card">
      <div>
        <p>Tirage numero: {lotoId.toString()}</p>

        {isDetailLoading ? (
          <Spinner />
        ) : (
          <>
            {typeof details[lotoId.toString()] !== "undefined" ? (
              <div className="app-card__subtext">
                <div>
                  Nombres de joueur: {details[lotoId.toString()].players}
                </div>
                <div>
                  Minimum: {details[lotoId.toString()].ticketPrice} ETH
                </div>
                <div>
                  Jackpot: {details[lotoId.toString()].pricePool} ETH
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        )}

        {typeof details[lotoId.toString()] !== "undefined" ? (
          <div className="app-card-buttons">
            {!details[lotoId.toString()].active && (
              <div>
                {details[lotoId.toString()].playersData.length &&
                details[lotoId.toString()].playersData.includes(
                  connectedAddr
                ) ? (
                  <span>Bonne chance!</span>
                ) : (
                  <div className="wrapper">
                    <input
                      type="number"
                      placeholder="montant.."
                      value={entryFee}
                      onChange={(e) => setEntryFee(e.target.value)}
                      className="input"
                    />{" "}
                    &nbsp;&nbsp;
                    <button
                      onClick={() => {
                        if (
                          details[lotoId.toString()].playersData.length &&
                          details[lotoId.toString()].playersData.includes(
                            connectedAddr
                          )
                        ) {
                          return;
                        }
                        enterLotoHandler(lotoId, entryFee);
                      }}
                      isLoading={entryBtnLoaders[lotoId]}
                      id={`enterBtn${lotoId}`}
                      className="content-button"
                    >
                        {!entryBtnLoaders[lotoId] ? (
                            <span>Participer</span>
                        ) : (
                            <Spinner loading size={20} />
                        )}
                    </button>
                  </div>
                )}

                {connectedAddr === manager &&
                  details[lotoId.toString()].players >= 1 && (
                    <button
                      onClick={() => {
                        pickWinnerHandler(lotoId);
                      }}
                      isLoading={winBtnLoaders[lotoId]}
                      className="content-button"
                    >
                        {!winBtnLoaders[lotoId] ? (
                            <span>GOOOOOO!</span>
                        ) : (
                            <Spinner loading size={20} />
                        )}
                    </button>
                  )}
              </div>
            )}
            {details[lotoId.toString()].active && (
              <>
                <div className="flex wrapper">
                  <span className="line">Gagnant: 🥳 </span> &nbsp;&nbsp;{" "}
                  <a
                    className="highlight"
                    href={
                      "https://goerli.etherscan.io/address/" +
                      details[lotoId.toString()].winner
                    }
                    target="_blank"
                    rel="noreferrer noopeneer"
                  >
                    {String(details[lotoId.toString()].winner).substring(
                      0, 6
                    ) +
                      "..." +
                      String(details[lotoId.toString()].winner).substring(
                        38
                      )}
                  </a>
                </div>
              </>
            )}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}

export default function LotoListing() {
    const [reloadList, setReloadList] = useState(true);
    const [declareWinner, setDeclareWinner] = useState(false);
    const [confetti, setConfetti] = useState(0);
    const { connectedAddr, connected } = useMetaMaskAccount();
    const [entryBtnLoaders, setEntryBtnLoaders] = useState({});
    const [winBtnLoaders, setWinBtnLoaders] = useState({});

    // modal
    // const [isOpen, setIsOpen] = useState({});
    // const [onClose, setOnClose] = useState({});
    // const [modalLotoId, setModalLotoId] = useState({});
    // const [details, setDetails] = useState({});
    // const [isDetailLoading, setIsDetailLoading] = useState({});

    const {
        txnError,
        callContract,
        waitData: entryLWaitData,
        waitLoading: entryLWaitLoading,
        wait,
    } = useLotoAction("enterLoto");

    const {
        txnError: startLError,
        callContract: startLoto,
        waitData: startLWaitResult,
        waitLoading: startLWaitLoading,
        wait: swait,
    } = useLotoAction("startLoto");

    const {
        txnError: winnerTxnErr,
        callContract: pickWinner,
        waitData: winLWaitData,
        waitLoading: winLWaitLoading,
        wait: pickWait,
    } = useLotoAction("pickWinner");

    const { fetchAllLotoIds } = useLotoContract();
    const { allLotoIds, manager } = useSelector((state) => state.loto);
    const loadingState = useSelector((state) => state.loading);

    const isLoading = checkIfLoading(loadingState, "FETCH_LOTO_IDS");
    const hasError = checkIfError(loadingState, "FETCH_LOTO_IDS");

    useEffect(() => {
        if (typeof startLWaitResult !== "undefined" && !startLWaitLoading) {
            handleEvents(startLWaitResult, "LotoCreated");
        }
    }, [startLWaitResult, startLWaitLoading]);

    useEffect(() => {
        if (typeof entryLWaitData !== "undefined" && !entryLWaitLoading) {
            handleEvents(entryLWaitData, "NewLotoPlayer");
        }
    }, [entryLWaitData, entryLWaitLoading]);

    useEffect(() => {
        if (typeof winLWaitData !== "undefined" && !winLWaitLoading) {
            handleEvents(winLWaitData, "RandomnessRequested");
        }
    }, [winLWaitData, winLWaitLoading]);

    const handleEvents = async (eventData, eventType) => {
        if (eventData.events) {
            const e = eventData.events.find((eve) => eve.event === eventType);
            if (typeof e !== "undefined") {
                toast("Succès: " + eventMessage(e.event));

                if (e.event === "NewLotoPlayer") {
                    setEntryBtnLoaders((prevS) => {
                        return {
                            ...prevS,
                            [e.args[0].toString()]: entryLWaitLoading,
                        };
                    });
                }

                if (e.event === "LotoCreated") {
                    setReloadList(true);
                }

                if (e.event === "RandomnessRequested") {
                    setWinBtnLoaders((prevS) => {
                        return {
                            ...prevS,
                            [e.args[1].toString()]: winLWaitLoading,
                        };
                    });
                }
            }
        }
    };

    useEffect(() => {
        handlerTxnErrors(txnError);
        setEntryBtnLoaders({});
    }, [txnError]);

    useEffect(() => {
        handlerTxnErrors(startLError);
    }, [startLError]);

    useEffect(() => {
        handlerTxnErrors(winnerTxnErr);
        setWinBtnLoaders({});
    }, [winnerTxnErr]);

    const handlerTxnErrors = async (err) => {
        if (typeof err !== "undefined") {
            if (err?.name && err.name === "UserRejectedRequestError") {
                toast("Erreur:  Vous avez annulée la transaction.");
                return;
            }
            if (err?.name && err.name === "Error") {
                toast("Erreur: Mauvais montant.");
                return;
            }
            toast("Erreur: " + getEMessage(err?.data?.message));
        }
    };

    useEffect(() => {
        if (!reloadList) return;
        (async () => {
            await fetchAllLotoIds();
            setReloadList(false);
        })();
    }, [reloadList]);

    const enterLotoHandler = useCallback(
        async (lotoId, ethValue) => {
            if (ethValue === "") return;
            if (!connected) {
                toast("Erreur: Connectez vous à MetaMask.");
                return;
            }
            setEntryBtnLoaders((prevS) => {
                return {
                    ...prevS,
                    [lotoId]: true,
                };
            });
            const txn = await callContract({
                args: [lotoId],
                overrides: {
                    value: ethers.utils.parseEther(ethValue.toString()),
                },
            });
            if (typeof txn.data !== "undefined") {
                await wait({ wait: txn.data.wait });
            }
        },
        [connected, callContract, wait]
    );

    //start loto action
    const startLotoHandler = async () => {
        if (!connected) {
            toast("Erreur: Connectez vous à MetaMask.");
            return;
        }
        const txn = await startLoto();
        if (typeof txn.data !== "undefined") {
            await swait({ wait: txn.data.wait });
        }
    };

    const pickWinnerHandler = useCallback(
        async (lotoId) => {
            if (!connected) {
                toast("Erreur: Connectez vous à MetaMask.");
                // toast({
                //   title: 'Erreur: Connectez vous à MetaMask.',
                //   status: 'ERRROR',
                //   isClosable: true,
                // });
                return;
            }
            setWinBtnLoaders((prevS) => {
                return {
                    ...prevS,
                    [lotoId]: true,
                };
            });
            const txn = await pickWinner({
                args: [lotoId],
            });
            if (typeof txn.data !== "undefined") {
                await pickWait({ wait: txn.data.wait });
            }
        },
        [connected, pickWait, pickWinner]
    );

    useEffect(() => {
        if(declareWinner){
            setConfetti(300);
            setTimeout(
                () => setConfetti(0), 
                20000
                );
        }
    },[declareWinner])

  return (
    <>
        <div className="main-container">
            <div className="main-header">
                <div className="header-menu dt">
                    <span>
                        ETHEREUM x FDJ x ETHEREUM x FDJ x ETHEREUM
                    </span>
                    {connected && connectedAddr === manager && (
                        <div>
                            <button
                                isLoading={startLWaitLoading}
                                onClick={startLotoHandler}
                                className="content-button"
                            >
                                Creer un nouveau tirage
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="content-wrapper">
                <div className="content-wrapper-header">
                    <div className="content-wrapper-context">
                        <h3 className="img-content">Loto de la Francaise des Jeux</h3>
                        <div className="content-text">
                            Participez a notre nouveau Loto et gagnez de l'Ethereum!
                        </div>
                    </div>
                    <img className="content-wrapper-img" src={LotoLarge} alt="" />
                </div>

                {(confetti !== 0) && <Confetti numberOfPieces={confetti}/>} 

                {!isLoading ? (
                    <div className="content-section">
                        {allLotoIds.length && !hasError ? (
                            <div className="apps-card">
                                {allLotoIds.map((data, index) => (
                                    <Loto
                                        index={index}
                                        key={index}
                                        lotoId={data}
                                        enterLotoHandler={enterLotoHandler}
                                        entryBtnLoaders={entryBtnLoaders}
                                        winBtnLoaders={winBtnLoaders}
                                        pickWinnerHandler={pickWinnerHandler}
                                        setDeclareWinner={setDeclareWinner}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div>
                                <div>Pas de tirage actif.</div>
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        <Spinner loading size={40} />
                        {/*<LotoDetailModal isOpen={isOpen} onClose={onClose} lId={modalLotoId} data={details} isDetailLoading={isDetailLoading} />*/}
                        {/*<ToastMessage message={hasError} toastType="error"/>*/}
                    </>
                )}
            </div>
        </div>
    </>
  );
}
