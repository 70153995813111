import { useEffect } from "react";
import { useSelector } from "react-redux";
import useLotoContract from "../hooks/useLotoContract";
import { checkIfLoading, checkIfError } from "../redux/reducers/selector";
import Modal from "../components/helpers/modal";
import GridSVG from "../assets/grid.svg";
import HandsSVG from "../assets/hands.svg";

export default function LotoStats() {
    const { getLotoManagerAddress, getLotoAllowedCount } =
    useLotoContract();
    const { manager, allowedCount } = useSelector((state) => state.loto);
    const loadingState = useSelector((state) => state.loading);
    const isLoadingManger = checkIfLoading(
        loadingState,
        "FETCH_LOTO_MANAGER"
        );
    const errorFetchingManager = checkIfError(
        loadingState,
        "FETCH_LOTO_MANAGER"
        );

    const isLoadingAllowedCount = checkIfLoading(
        loadingState,
        "FETCHING_ALLOWED_COUNT"
        );
    const errorFetchingAllowedCount = checkIfError(
        loadingState,
        "FETCHING_ALLOWED_COUNT"
        );

    useEffect(() => {
        (async () => {
            await getLotoManagerAddress();
        })();
    }, [getLotoManagerAddress]);

    useEffect(() => {
        (async () => {
            await getLotoAllowedCount();
        })();
    }, [getLotoAllowedCount]);

    return (
        <div className="left-side">
            <div className="side-wrapper">
            <div className="side-title">Addresse du Contract</div>
            <div className="side-menu">
            <a
                href={"https://goerli.etherscan.io/address/" + process.env.REACT_APP_LOTO_CONTRACT}
                target="_blank"
                rel="noopeneer noreferrer"
            >
                <img src={GridSVG} alt="item" width="16" height="16" className="mr5" />
                {String(process.env.REACT_APP_LOTO_CONTRACT).substring(0, 6) + "..." + String(process.env.REACT_APP_LOTO_CONTRACT).substring(38)}
            </a>
            </div>
            </div>
            <div className="side-wrapper">
                <div className="side-title">Par tirage</div>
                <div className="side-menu">
                    <a href={"https://goerli.etherscan.io/address/" + process.env.REACT_APP_LOTO_CONTRACT} target="_blank" rel="noopeneer noreferrer">
                        <img src={GridSVG} alt="item" width="16" height="16" className="mr5" /> 
                        {isLoadingAllowedCount ? "Chargement en cours..." : allowedCount && !errorFetchingAllowedCount && allowedCount}{" "} joueurs autorisés
                    </a>
                </div>
            </div>
            <div className="side-wrapper">
                <div className="side-title">Manager</div>
                <div className="side-menu">
                    <a
                        href={"https://goerli.etherscan.io/address/" + manager}
                        target="_blank"
                        rel="noopeneer noreferrer"
                    >
                        <img src={HandsSVG} alt="item" width="20" height="20" className="mr5" />
                        {String( isLoadingManger ? "Chargement en cours..." : manager && !errorFetchingManager && manager ).substring(0, 6) + "..." + String( isLoadingManger ? "Chargement en cours..." : manager && !errorFetchingManager && manager ).substring(38)}
                    </a>
                </div>

                <br />
                <br />

                {/* helpers */}

                <Modal  className="p10"
                        activator={({ setShow }) => (

                    <button
                        className="content-button"
                        type="button"
                        onClick={() => setShow(true)}
                    >
                        About
                    </button>
                    )}
                >

                    <h2 className="mb-5"><b>About</b></h2>

                    <p className="border-b pb-4 text-base">This is a project I built for fun, it can run on any EVM blockchain. It uses a solidity smart contract to create and manage 'radom games'. And it uses Chainlink VRF to provide cryptographically secure randomness and transparency when selecting a winner.</p>

                    <h3 className="font-bold text-lg mb-5">To play:</h3>

                    <p className="text-base border-b pb-4">Connect with Metamask and select goerli network. Once connected you can enter any open game, the minimum to enter is 0.5 ETH. Get some testnet ETH here: <a href="https://goerlifaucet.com/" rel="noopener noreferrer" target="_blank"><u>goerli Faucet</u></a>.</p>
                    <br />
                    <p className="text-base">Thank you for visiting. <br /><br /><br /> <a href="https://linktr.ee/jonahlmadeya" target="_blank" rel="noreferrer noopener"><u>View more of my projects!</u></a> <br /> <br /> Jonah.</p>


                </Modal>

            </div>
        </div>
    );
}
