export const FETCHING_LOTO_DETAIL = "FETCHING_LOTO_DETAIL";
export const FETCHING_LOTO_DETAIL_SUCCESS = "FETCHING_LOTO_DETAIL_SUCCESS";

export const FETCHING_ALLOWED_COUNT = "FETCHING_ALLOWED_COUNT";
export const FETCHING_ALLOWED_COUNT_SUCCESS = "FETCHING_ALLOWED_COUNT_SUCCESS";

export const FETCH_LOTO_MANAGER = "FETCH_LOTO_MANAGER";
export const FETCH_LOTO_MANAGER_SUCCESS = "FETCH_LOTO_MANAGER_SUCCESS";

export const FETCH_LOTO_IDS = "FETCH_LOTO_IDS";
export const FETCH_LOTO_IDS_SUCCESS = "FETCH_LOTO_IDS_SUCCESS";
