import LotoContract from "../contracts/Loto.json";
import { useContractWrite, useWaitForTransaction } from "wagmi";

const useLotoAction = (funcName) => {
    const [{ data, error, loading }, write] = useContractWrite(
        {
            addressOrName: process.env.REACT_APP_LOTO_CONTRACT,
            contractInterface: LotoContract.abi,
        },
        funcName
    );

    const [{ data: waitData, error: waitError, loading: waitLoading }, wait] =
    useWaitForTransaction({
        skip: true,
    });

    return {
        txnData: data,
        txnError: error,
        txnLoading: loading,
        callContract: write,
        LotoContract,
        waitData,
        waitError,
        waitLoading,
        wait,
    };
};

export default useLotoAction;
