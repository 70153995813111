import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { providers } from "ethers";
import { Provider as WagmiProvider, defaultChains } from "wagmi";
import { AccountProvider } from "./context/AccountContext";
import { InjectedConnector } from "wagmi/connectors/injected";
import { Provider as RProvider } from "react-redux";
import store from "./redux/store";
import "./styles/theme.css";
import { ToastContainer } from "react-toastify";

const provider = providers.getDefaultProvider(
    process.env.REACT_APP_PROVIDER_URL,
    {
        infura: process.env.REACT_APP_INFURA_KEY,
        alchemy: process.env.REACT_APP_ALCHEMY_KEY,
    }
);

const connector = [
    new InjectedConnector({
        chains: defaultChains.filter((c) => c.id === 4),
        options: { shimDisconnect: true },
    }),
];

ReactDOM.render(
    <React.StrictMode>
        <WagmiProvider autoConnect provider={provider} connector={connector}>
            <AccountProvider>
                <RProvider store={store}>
                    <App />
                    <ToastContainer
                        autoClose={false}
                        draggableDirection="x"
                        closeOnClick
                        pauseOnFocusLoss={false}
                    />
                        <div className="footer">
                        <a className="credit" href="https://www.jonahlmadeya.com/" target="_blank">Built by Jonah | </a>
                        <a className="credit" href="https://dribbble.com/galeziowski" target="_blank" rel="noopener noreferrer"> &nbsp; Design from @galeziowski</a>
                    </div>
                </RProvider>
            </AccountProvider>
        </WagmiProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
