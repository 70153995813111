import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useMetaMaskAccount } from "../context/AccountContext";

const NavBar = () => {
    const {
        connectedAddr,
        connected,
        connectToMetaMask,
        disconnect,
        loading,
        netWorkName,
        accountErrorMessage,
    } = useMetaMaskAccount();

    const exitGame =()=> {
        let choice = window.confirm("Exit game?");
        if (choice) {
            window.open("https://linktr.ee/jonahlmadeya", "_self");
            window.close();
        } else {

        }
    }

    useEffect(() => {
        if (accountErrorMessage !== "") {
            toast("Error: " + accountErrorMessage);
        }
    }, [accountErrorMessage]);

    return (
        <div className="header">
            <div className="menu-circle" onClick={()=>{exitGame()}}></div>
            <div className="header-menu">
                <span className="menu-link is-active">Game</span>
                <span className="menu-link dt">Settings</span>
            </div>
            <div className="header-account">
                {!connected ? (
                    <></>
                ) : (
                    <h5 className="content-section-title">
                        {"0x.." + String(connectedAddr).substring(38)}{" "}
                        <span className="content-section-title dt">
                            &nbsp;&nbsp;{netWorkName !== "" && netWorkName}
                        </span>
                    </h5>
                )}
                {!connected ? (
                    <button
                        className="content-button"
                        onClick={connectToMetaMask}
                        isLoading={loading}
                    >
                        Connection
                    </button>
                ) : (
                    <>
                        &nbsp;&nbsp;
                        <button
                            onClick={disconnect}
                            isLoading={loading}
                            className="content-button"
                        >
                            Déconnexion
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default NavBar;
