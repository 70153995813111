import React from "react";
import LotoListing from "./LotoListing";
import LotoStats from "./LotoStats";

const LotoDashBoard = () => {
    return (
        <div className="wrapper">
            <LotoStats />
            <LotoListing />
        </div>
    );
};

export default LotoDashBoard;
