import React from "react";

import NavBar from "./components/NavBar";
import LotoDashBoard from "./components/LotoDashBoard";

function App() {
    return (
        <div className="app">
            <NavBar />
            <LotoDashBoard />
        </div>
    );
}

export default App;
